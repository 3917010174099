#surveyElement-dark {
    /* Paste variables here */
    --primary: var(--mui-palette-primary-main, #d70001);
    --background: var(--mui-palette-background-paper, #2c2c2c);
    --background-dim: var(--mui-palette-background-default, #343434);
    --background-dim-light: #343434;
    --primary-foreground: var(--mui-palette-primary-contrastText, #ffffff);
    --foreground: #ededed;
    --base-unit: 6px;
}

#surveyElement-light {
    /* Paste variables here */
    --primary: var(--mui-palette-primary-main, #d70001);
    --background: var(--mui-palette-background-paper, #ffffff);
    --background-dim: var(--mui-palette-background-default, #fffbeb);
    --background-dim-light: #f2f2f2;
    --primary-foreground: var(--mui-palette-primary-contrastText, #ffffff);
    --foreground: #161616;
    --base-unit: 6px;

}

.sd-input--disabled,
.sd-input--disabled::placeholder {
    opacity: 0.8;
}