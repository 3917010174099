
/* Inter Font - Regular */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://rsms.me/inter/font-files/Inter-Regular.woff2?v=3.19') format('woff2');
  }
  
  /* Inter Font - Bold */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://rsms.me/inter/font-files/Inter-Bold.woff2?v=3.19') format('woff2');
  }

  .MuiTypography-root {
    font-family: 'Inter', sans-serif !important;
  }
  .texture-shattered {
    background: url( '../../assets/images/landing/shattered.png') !important;
  }
